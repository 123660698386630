<template>
  <div id="addProject" class="modal fade" tabindex="-1" aria-labelledby="addProjectLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="addProjectLabel" class="modal-title">Add new project</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div class="modal-body">
          <div v-if="error" class="row text-center">
            <p>{{ error }}</p>
          </div>
          <form class="row">
            <div class="mb-3 col-md-6">
              <div class="form-floating">
                <input v-model="newProject.project" type="text" class="form-control" placeholder="Project" />
                <label>Project*</label>
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <div class="form-floating">
                <input v-model="newProject.client" type="text" class="form-control" placeholder="Client" />
                <label>Client</label>
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <div class="form-floating">
                <input v-model="newProject.perHour" type="number" class="form-control" placeholder="Per hour" />
                <label>Per hour*</label>
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <div class="form-floating">
                <input v-model="newProject.perHourCurrency" type="text" class="form-control" placeholder="Currency" />
                <label>Currency</label>
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <div class="form-floating">
                <input v-model="newProject.emailAddress" type="text" class="form-control" placeholder="Email Address" />
                <label>Email Address</label>
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <div class="form-floating">
                <input v-model="newProject.website" type="text" class="form-control" placeholder="Website" />
                <label>Website</label>
              </div>
            </div>
            <div class="mb-3 col-md-12">
              <div class="form-floating">
                <input v-model="newProject.address" type="text" class="form-control" placeholder="Address" required />
                <label>Address*</label>
              </div>
            </div>
            <div class="mb-3 col-md-12">
              <div class="form-floating">
                <input v-model="newProject.city" type="text" class="form-control" placeholder="City" required />
                <label>City*</label>
              </div>
            </div>
            <div class="mb-3 col-md-12">
              <div class="form-floating">
                <input v-model="newProject.country" type="text" class="form-control" placeholder="Country" required />
                <label>Country*</label>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-success" @click.prevent="addProject()">Add</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectAdd',
  data() {
    return {
      newProject: {
        id: '',
        project: '',
        client: '',
        perHour: '',
        perHourCurrency: '',
        email: '',
        website: '',
        address: '',
        city: '',
        country: '',
      },
      error: '',
    };
  },
  methods: {
    addProject() {
      if (!this.newProject.project || !this.newProject.perHour) {
        this.error = 'Project name and price per hour are required';
      } else {
        this.error = '';
        const project = {
          id: Date.now(),
          project: this.newProject.project,
          client: this.newProject.client,
          perHour: this.newProject.perHour,
          perHourCurrency: this.newProject.perHourCurrency,
          email: this.newProject.email,
          website: this.newProject.website,
          address: this.newProject.address,
          city: this.newProject.city,
          country: this.newProject.country,
        };

        this.$emit('add-project', project);

        this.id = '';
        this.newProject.project = '';
        this.newProject.client = '';
        this.newProject.perHour = '';
        this.newProject.perHourCurrency = '';
        this.newProject.email = '';
        this.newProject.website = '';
        this.newProject.address = '';
        this.newProject.city = '';
        this.newProject.country = '';
      }
    },
  },
};
</script>

<style></style>
