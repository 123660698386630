<template>
  <div class="container-fluid g-0">
    <div class="row">
      <project-add :projects="projects" @add-project="add($event)" />
      <div class="col-md-12">
        <h4 class="mt-3">
          Projects
          <button class="add-project btn bg-success text-white" data-bs-toggle="modal" data-bs-target="#addProject">
            Add project
          </button>
        </h4>
      </div>
      <div class="col-md-12">
        <table class="table align-middle">
          <thead class="table-dark table-hover">
            <tr class="d-flex">
              <th class="col-4 d-flex align-items-center">Project</th>
              <th class="col-3 d-flex align-items-center">Client</th>
              <th class="col-3 d-flex align-items-center">Per hour</th>
              <th class="col-2 d-flex align-items-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="project in projects" :key="project.id" class="d-flex">
              <td class="col-4 d-flex align-items-center">{{ project.project }}</td>
              <td class="col-3 d-flex align-items-center">{{ project.client }}</td>
              <td class="col-3 d-flex align-items-center">
                {{ project.perHour }}
                {{ project.perHourCurrency }}
              </td>
              <td class="col-2 table-action">
                <div class="btn-group" role="group" aria-label="">
                  <router-link
                    class="btn bg-primary text-white"
                    :to="{
                      name: 'ProjectEdit',
                      params: { id: project.id },
                    }"
                  >
                    <i class="fas fa-pen"></i>
                  </router-link>
                  <button class="btn bg-danger text-white" @click="remove(project.id)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectAdd from '@/components/ProjectAdd.vue';

import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Projects',
  components: { ProjectAdd },
  data: () => ({
    state: {},
  }),
  computed: {
    ...mapGetters(['projects']),
  },
  methods: {
    ...mapActions(['getProjects', 'addProject', 'removeProject']),
    add(project) {
      this.addProject(project);
    },
    remove(id) {
      const result = confirm('Are you sure you want to delete?');
      if (result) {
        this.removeProject(id);
      }
    },
  },
  created() {
    this.getProjects();
  },
};
</script>

<style scoped>
button.add-project {
  margin-left: 15px;
}
.table-action .btn {
  padding: 5px 10px !important;
}
@media (max-width: 987px) {
  .table-action .btn {
    margin: 0 !important;
    padding: 5px 7px !important;
  }
}
</style>
